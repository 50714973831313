@import '~overlayscrollbars/css/OverlayScrollbars.css';
@import '@carbon/themes/scss/themes';
@import 'editorjs/nested-list/styles';

$carbon--theme: $carbon--theme--g10;
@include carbon--theme();

$feature-flags: (
  grid-columns-16: true,
);

@import 'carbon-components/scss/globals/scss/styles.scss';

.#{$prefix}--table-toolbar {
  overflow: visible;
  position: relative;
  z-index: 5000;
}

.cdx-settings-button:nth-child(n + 4) {
  margin-top: 0;
}
